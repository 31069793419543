<template>
    <div>
        <h6>{{$t('accountViews.changePwdForm.header')}}</h6>
        <form>
            <div class="row">
                <div class="col-12">
                    <fg-input type="password"
                                :label="$t('accountViews.changePwdForm.form.oldPassword.label')" name="oldPassword"
                                :placeholder="$t('accountViews.changePwdForm.form.oldPassword.placeholder')" :error="getError('oldPassword')"
                                v-model="changePwd.oldPassword" v-validate="modelValidations.oldPassword">
                    </fg-input>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <fg-input type="password"
                                :label="$t('accountViews.changePwdForm.form.newPassword.label')" name="newPassword"
                                :placeholder="$t('accountViews.changePwdForm.form.newPassword.placeholder')" :error="getError('newPassword')"
                                v-model="changePwd.newPassword" v-validate="modelValidations.newPassword" ref="newPassword">
                    </fg-input>
                </div>
            </div>
            <div class="row">
                <div  class="col-12">
                    <fg-input type="password"
                                :label="$t('accountViews.changePwdForm.form.confirmPassword.label')" name="confirmPassword"
                                :placeholder="$t('accountViews.changePwdForm.form.confirmPassword.placeholder')" :error="getError('confirmPassword')"
                                v-model="changePwd.confirmPassword" v-validate="modelValidations.confirmPassword">
                    </fg-input>
                </div>
            </div>

            <div class="row mt-3 justify-content-center">
                <div class="col-12 col-md-auto text-center">
                    <p-button native-type="submit" type="info" class="w-100" @click.prevent="validate">{{$t('accountViews.changePwdForm.btnChangePwd')}}</p-button>
                </div>
            </div>
        </form>            
    </div>
</template>
<script>
    import { Button } from 'src/components/UIComponents'
    import swal from 'sweetalert2'

    export default {
        components: {
            [Button.name]: Button
        },
        data() {
            var vueThis = this;
            return {
                changePwd: {
                    oldPassword: '',
                    newPassword: '',
                    confirmPassword: ''
                },
                modelValidations: {
                    oldPassword: {
                        required: true,
                    },
                    newPassword: {
                        required: true,
                        regex: vueThis.$root.config.passwordRegex,
                        max: 200,
                    },
                    confirmPassword: {
                        required: true,
                        confirmed: 'newPassword',
                        regex: vueThis.$root.config.passwordRegex,
                        max: 200,
                    },
                }
            }
        },
        methods: {
            changePassword() {
                let vueThis = this;
                this.changePwd.accountHID = vueThis.$store.getters.userState.accountHID;
                this.axios.post(this.$root.config.account.changePwdUrl(), this.changePwd)
                    .then(response => {
                        swal.fire({
                            icon: 'success',
                            title: vueThis.$t('accountViews.changePwdForm.changeSuccess'),
                            showConfirmButton: true,
                            timer: vueThis.$root.config.alertTimer15,
                            timerProgressBar: true,
                        });
                        this.changePwd.oldPassword = '';
                        this.changePwd.newPassword = '';
                        this.changePwd.confirmPassword = '';
                        this.$validator.reset();

                    }).catch(function (error) {
                        if (error.response.status == 400) {
                            //console.log(error.response.data);
                            swal.fire({
                                icon: 'warning',
                                title: vueThis.$t('accountViews.changePwdForm.changeFailed'),
                                showConfirmButton: true,
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.genericErrorAlert();
                        }
                    });
            },
            getError(fieldName) {
                let familierName = this.$t('accountViews.changePwdForm.form.' + fieldName + '.familierName');

                var errMsg = this.errors.first(fieldName);
                if (errMsg === undefined) return errMsg;
                errMsg = errMsg.replace(fieldName, familierName);

                if (fieldName == "newPassword" && this.errors.items.some(c => c.field === "newPassword" && c.rule === "regex")) {
                    errMsg = this.$t('accountViews.changePwdForm.form.newPassword.passwordRegexErrMsg');
                }
                if (fieldName == "confirmPassword" && this.errors.items.some(c => c.field === "confirmPassword" && c.rule === "regex")) {
                    errMsg = this.$t('accountViews.changePwdForm.form.confirmPassword.passwordRegexErrMsg');
                }
                return errMsg;
            },
            validate() {
                this.$validator.validateAll().then(isValid => {
                    if (isValid) {
                        this.changePassword();
                    }
                })
            }
        }
    }

</script>
<style>
</style>
