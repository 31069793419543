<template>
    <div>
        <div class="mb-3" data-v-step="step-tfilter">
            <div class="btn-group d-none d-md-block">
                <p-button type="primary" round outline nativeLabel @click="filterStatus('contents')" :class="{active: filter.status === 'contents' }"> <i class="fa-solid fa-book"></i> {{$t('entityViews.training.filter.contentsLabel')}}</p-button>
                <p-button type="primary" round outline nativeLabel @click="filterStatus('questions')" :class="{active: filter.status === 'questions' }"> <i class="fa-solid fa-circle-question"></i> {{$t('entityViews.training.filter.questionsLabel')}}</p-button>
                <p-button type="primary" round outline nativeLabel @click="filterStatus('portal')" :class="{active: filter.status === 'portal' }"> <i class="fa-solid fa-desktop"></i> {{$t('entityViews.training.filter.portalLabel')}}</p-button>
                <p-button type="primary" round outline nativeLabel @click="filterStatus('phishingreporting')" :class="{active: filter.status === 'phishingreporting' }"> <i class="fa-solid fa-flag"></i> {{$t('entityViews.training.filter.phishingReportingLabel')}}</p-button>
            </div>
            <div class="row d-md-none">
                <div class="col-12">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="tilterStatus('contents')" :class="{active: filter.status === 'contents' }"> <i class="fa-solid fa-book"></i> {{$t('entityViews.training.filter.contentsLabel')}}</p-button>
                </div>
                <div class="col-12 col-sm-6">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="filterStatus('questions')" :class="{active: filter.status === 'questions' }"> <i class="fa-solid fa-circle-question"></i> {{$t('entityViews.training.filter.questionsLabel')}}</p-button>
                </div>
                <div class="col-12 col-sm-6">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="filterStatus('portal')" :class="{active: filter.status === 'portal' }"> <i class="fa-solid fa-desktop"></i> {{$t('entityViews.training.filter.portalLabel')}}</p-button>
                </div>
                <div class="col-12 col-sm-6">
                    <p-button type="primary" class="w-100" outline nativeLabel @click="filterStatus('phishingreporting')" :class="{active: filter.status === 'phishingreporting' }"> <i class="fa-solid fa-flag"></i> {{$t('entityViews.training.filter.phishingReportingLabel')}}</p-button>
                </div>
            </div>
        </div>

        <div ref="trainingContent" v-if="filter.status === 'contents'">
            <training-content-table :entityHID="entityHID" ref="tcView">
            </training-content-table>
        </div>
        <div ref="trainingQuestion" v-if="filter.status === 'questions'">
            <training-question-table :entityHID="entityHID" ref="tqView">
            </training-question-table>
        </div>
        <div ref="portal" v-if="filter.status === 'portal'">
            <div class="card" ref="content">
                <div class="card-header">
                    <h4 class="card-title" data-v-step="step-name">{{$t('entityViews.training.portal.header')}}</h4>
                </div>
                <div class="card-body">
                    <p>
                        {{$t('entityViews.training.portal.text1')}}
                    </p>
                    <p>
                        {{$t('entityViews.training.portal.text2')}}
                    </p>
                    <p>
                        {{$t('entityViews.training.portal.text3')}}
                    </p>
                    <p>
                        {{$t('entityViews.training.portal.text4')}}
                    </p>

                    <!--<a :href="portalSampleUrl" class="mt-2 btn btn-default" target="_blank">
                    {{$t('entityViews.training.portal.btnOpenPortal')}}
                </a>-->
                    <p-button type="default" class="mt-2" @click="openTPTab" :disabled="loadingTPUrl">
                        <i class="fa-solid fa-spinner fa-spin-pulse" v-if="loadingTPUrl"></i>
                        {{$t('entityViews.training.portal.btnOpenPortal')}}
                    </p-button>
                </div>
            </div>
        </div>
        <div ref="phishingReporting" v-if="filter.status === 'phishingreporting'">
            <phishing-reporting :entityHID="entityHID" ref="prView">
            </phishing-reporting>
        </div>
    </div>
</template>

<script>
    import TCTable from 'src/components/UIComponents/Training/TrainingContentTable.vue'
    import TQTable from 'src/components/UIComponents/Training/TrainingQuestionTable.vue'
    import PhishingReporting from 'src/components/UIComponents/PhishingReporting/PhishingReporting.vue'


    export default {
        components: {
            [TCTable.name]: TCTable,
            [TQTable.name]: TQTable,
            [PhishingReporting.name]: PhishingReporting,
        },
        data() {
            return {
                entityHID: '',
                filter: {
                    status: 'contents'
                },
                portalSampleUrl: '',
                loadingTPUrl: true,
            }
        },
        methods: {
            initComponent: function () {
                //if (this.filter.status === "contents") {
                //    setTimeout(() => this.$refs.tcView.refresh(), 300);
                //}
                //if (this.filter.status === "questions") {
                //    setTimeout(() => this.$refs.tqView.refresh(), 300);
                //}
                if (this.filter.status === "portal") {
                    this.loadPortalSample();
                }
            },
            filterStatus(status) {
                if (this.filter.status != status) {
                    this.$router.push({ name: 'eTraining', query: { s: status } });
                }
                else {
                    this.filter.status = status;
                }
            },
            handleSortChange(e) {
                this.sortObject = { order: e.order, name: e.prop };
            },
            loadPortalSample() {
                if (!this.entityHID) return;
                let vueThis = this;
                this.loadingTPUrl = true;
                this.axios.post(this.$root.config.entity.training.getTrainingPortalSampleUrlUrl(this.entityHID), null)
                    .then(response => {
                        vueThis.loadingTPUrl = false;
                        vueThis.portalSampleUrl = response.data;                        
                    }).catch(function (ex) {
                        vueThis.loadingTPUrl = false;
                        vueThis.writeEx2Log(ex);
                    });
            },
            openTPTab() {
                window.open(this.portalSampleUrl, '_blank').focus();
            },
        },
        mounted: function () {
            this.initComponent();
        },
        watch: {
            $route(to, from) {
                if (to !== from) {
                    this.entityHID = this.$route.params.ehid;
                    let s = this.$route.query.s;
                    if (s == undefined) s = 'contents';
                    this.filter.status = s;

                    this.initComponent();
                }
            }
        },
        created() {
            this.entityHID = this.$route.params.ehid;
            let s = this.$route.query.s;
            if (s == undefined) s = 'contents';
            this.filter.status = s;
        }
    }


</script>

<style scoped>
</style>