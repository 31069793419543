<template>
    <div>
        <!-- timeline -->
        <div class="row">
            <div class="col-12 hvresult-timeline" ref="hvresulttimeline">
                <time-line type="simple">
                    <time-line-item inverted :badge-type="hvr.lineType" :badge-icon="hvr.lineIcon" v-for="hvr of hvResults" v-bind:key="hvr.campaignResultDetailID">
                        <badge slot="header" :type="hvr.lineType">{{hvr.typeLabel}}</badge>
                        <div slot="content">
                            <div class="row">
                                <div class="col">
                                    <div><label><b>{{$t('hvResult.hvResultTimeLine.item.uidLabel')}}</b> {{hvr.campaignResultHID}} ({{hvr.campaignResultDetailID}})</label></div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <label><b>{{$t('hvResult.hvResultTimeLine.item.tsLabel')}}</b>{{hvr.timeStampUtc | moment("DD.MM.YYYY HH:mm:ss")}}</label>
                                </div>
                                <div class="col">
                                    <label><b>{{$t('hvResult.hvResultTimeLine.item.ipLabel')}}</b> {{hvr.address}}</label>
                                </div>
                                <div class="col">
                                    <label><b>{{$t('hvResult.hvResultTimeLine.item.browserLabel')}}</b> {{hvr.browserName}}</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div><label><b>{{$t('hvResult.hvResultTimeLine.item.payloadLabel')}}</b> </label></div>
                                    <div><label>{{hvr.payload}}</label></div>
                                </div>
                            </div>
                        </div>
                        <div slot="footer">
                        </div>
                    </time-line-item>
                </time-line>
            </div>
        </div>
    </div>
</template>

<script>
    import { CampaignTools, HvGroupTools } from 'src/lpLibrary.js'
    import platform from 'platform'


    import { Input, Select, Option, Table, Card, TableColumn } from 'element-ui'
    import { Modal, Pagination, TimeLine, TimeLineItem, Badge } from 'src/components/UIComponents'

    import swal from 'sweetalert2'

    export default {
        name: 'hv-result-timeline',
        props: {
            entityHID: {
                type: String,
                required: true
            },
            hvHID: String,
            resultGroupHID: String,
        },
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            Modal,
            Card,
            [Pagination.name]: Pagination,
            TimeLine,
            TimeLineItem,
            [Badge.name]: Badge
        },
        data() {
            return {
                hvResults: [],
            }
        },
        methods: {
            initComponent: function () {
                this.loadHvResults();
            },
            loadHvResults: function () {
                if (this.hvHID === undefined || this.hvHID == 0) {
                    return;
                }
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.hvresulttimeline));
                this.axios.post(this.$root.config.entity.report.hoaxVictimResultTimeLineUrl(this.entityHID), null, { params: { hvHID: this.hvHID, resultGroupHID: this.resultGroupHID } })
                    .then(response => {
                        loader.hide();
                        this.hvResults = response.data;
                        this.hvResults.forEach(item => {
                            let channelType = vueThis.$t('hvResult.hvResultTimeLine.item.defaultChLabel');
                            switch (item.channelType) {
                                case "SMS":
                                    channelType = vueThis.$t('hvResult.hvResultTimeLine.item.smsChLabel');
                                    break;
                                case "Email":
                                    channelType = vueThis.$t('hvResult.hvResultTimeLine.item.emailChLabel');
                                    break;
                            }
                            switch (item.eventType) {
                                case "Sent":
                                    item.lineIcon = "fa-solid fa-paper-plane fa-2x mr-1";
                                    item.lineType = "sent";
                                    item.typeLabel = vueThis.$t('hvResult.hvResultTimeLine.item.sentLabel');
                                    break;
                                case "Interacted":
                                    item.lineIcon = "fa-solid fa-envelope-open-text fa-2x";
                                    item.lineType = "interacted";
                                    item.typeLabel = vueThis.$t('hvResult.hvResultTimeLine.item.interactedLabel');
                                    break;
                                case "LinkClicked":
                                    item.lineIcon = "fa-solid fa-hand-point-up fa-2x";
                                    item.lineType = "linkClicked";
                                    item.typeLabel = vueThis.$t('hvResult.hvResultTimeLine.item.linkClickedLabel');
                                    break;
                                case "DataSubmitted":
                                    item.lineIcon = "nc-icon nc-paper";
                                    item.lineType = "dataSubmitted";
                                    item.typeLabel = vueThis.$t('hvResult.hvResultTimeLine.item.dataSubmittedLabel');
                                    break;
                                case "Trained":
                                    item.lineIcon = "fa-brands fa-leanpub fa-2x";
                                    item.lineType = "trained";
                                    item.typeLabel = vueThis.$t('hvResult.hvResultTimeLine.item.trainedLabel');
                                    break;
                                case "Reported":
                                    item.lineIcon = "fa fa-flag fa-2x";
                                    item.lineType = "reported";
                                    item.typeLabel = vueThis.$t('hvResult.hvResultTimeLine.item.reportedLabel');
                                    break;
                                case "FileOpened":
                                    item.lineIcon = "fa-regular fa-folder-open fa-2x";
                                    item.lineType = "fileOpened";
                                    item.typeLabel = vueThis.$t('hvResult.hvResultTimeLine.item.fileOpenedLabel');
                                    break;
                                default:
                                    item.lineIcon = "nc-icon";
                                    item.lineType = "default";
                                    item.typeLabel = "NaN";
                            }
                            item.typeLabel = channelType + item.typeLabel;
                            item.browserName = "-";
                            if (item.browser != null && item.browser.length > 0) {
                                let plt = platform.parse(item.browser);;
                                item.browserName = plt.name;
                            }
                        });
                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        loader.hide();
                    });
            },
            handleSortChange(e) {
                this.sortObject = { order: e.order, name: e.prop };
            },
        },
        mounted: function () {
            this.initComponent();
        },
    }

</script>

<style scoped>
    .hvresult-timeline {
        background-color: #ebecf1;
    }

        .hvresult-timeline .card.card-timeline {
            overflow-x: auto;
            max-height: 500px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
</style>