<template>
    <div>
        <div class="row">
            <div class="col-12 hvscorechart-container" ref="hvscorechart">
                <h3 class="float-sm-left">Score trend</h3>
                <el-select class="select-default float-sm-right"
                           v-model="timefilter">
                    <el-option class="select-default"
                               v-for="item in timeSelectors"
                               :key="item.key"
                               :label="item.label"
                               :value="item.key">
                    </el-option>
                </el-select>
                <line-chart :labels="chartXAxisLabel" ref="lineChart"
                            :datasets="chartDataSets"
                            :height="100"
                            :extraOptions="extraOptions">
                </line-chart>
            </div>
        </div>
    </div>
</template>

<script>
    import { MaturityLevelTools, } from 'src/lpLibrary.js'
    import LineChart from 'src/components/UIComponents/Charts/LineChart'
    import { Select, Option } from 'element-ui'

    export default {
        name: 'hv-score-chart',
        components: {
            LineChart,
            [Select.name]: Select,
            [Option.name]: Option,
        },
        props: {
            entityHID: {
                type: String,
                required: true
            },
            hvHID: String,
        },
        data() {
            return {
                hvScoreDetail: undefined,
                extraOptions: {
                    legend: {
                        display: false,
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                precision: 0,
                                max: 100,
                                min: 0,
                            }
                        }]
                    },
                    tooltips: {
                        tooltipFillColor: "rgba(0,0,0,0.5)",
                        tooltipFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        tooltipFontSize: 14,
                        tooltipFontStyle: "normal",
                        tooltipFontColor: "#fff",
                        tooltipTitleFontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        tooltipTitleFontSize: 14,
                        tooltipTitleFontStyle: "bold",
                        tooltipTitleFontColor: "#fff",
                        tooltipYPadding: 6,
                        tooltipXPadding: 6,
                        tooltipCaretSize: 8,
                        tooltipCornerRadius: 6,
                        tooltipXOffset: 10,
                    },
                    annotation: {
                        drawTime: 'beforeDatasetsDraw',
                        annotations: [
                            {
                                yScaleID: "y-axis-0",
                                type: "box",
                                yMin: 0,
                                yMax: 20,
                                backgroundColor: MaturityLevelTools.Color_Group1_T,
                                borderWidth: 0,
                                borderColor: "rgba(0,0,0,0)",
                            },
                            {
                                yScaleID: "y-axis-0",
                                type: "box",
                                yMin: 20,
                                yMax: 40,
                                backgroundColor: MaturityLevelTools.Color_Group2_T,
                                borderWidth: 0,
                                borderColor: "rgba(0,0,0,0)",
                            },
                            {
                                yScaleID: "y-axis-0",
                                type: "box",
                                yMin: 40,
                                yMax: 60,
                                backgroundColor: MaturityLevelTools.Color_Group3_T,
                                borderWidth: 0,
                                borderColor: "rgba(0,0,0,0)",
                            },
                            {
                                yScaleID: "y-axis-0",
                                type: "box",
                                yMin: 60,
                                yMax: 80,
                                backgroundColor: MaturityLevelTools.Color_Group4_T,
                                borderWidth: 0,
                                borderColor: "rgba(0,0,0,0)",
                            },
                            {
                                yScaleID: "y-axis-0",
                                type: "box",
                                yMin: 80,
                                yMax: 100,
                                backgroundColor: MaturityLevelTools.Color_Group5_T,
                                borderWidth: 0,
                                borderColor: "rgba(0,0,0,0)",
                            },
                        ]
                    },
                },
                timeSelectors: [
                    { label: "last 3 months", key: "last3m", days: 91 },
                    { label: "last 6 months", key: "last6m", days: 183 },
                    { label: "last year", key: "last1y", days: 365 },
                ],
                timefilter: 'last3m',
            }
        },
        methods: {
            initComponent: function () {
                this.loadHvScoreDetail();
            },
            loadHvScoreDetail: function () {
                if (this.hvHID === undefined || this.hvHID == '') {
                    return;
                }
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.hvscorechart));
                this.axios.post(this.$root.config.entity.hv.hoaxVictimScoreDetailUrl(this.entityHID), null, { params: { hvHID: this.hvHID } })
                    .then(response => {
                        loader.hide();
                        this.hvScoreDetail = response.data;
                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        loader.hide();
                    });
            },
        },
        computed: {
            filteredData() {
                if (this.hvScoreDetail == undefined || this.hvScoreDetail.chartData.length == 0) return [];

                let lastDate = new Date(Date.now());
                if (this.LastDate != null && this.LastDate != undefined)
                    lastDate = this.LastDate;

                let tFilter = this.timeSelectors.find(c => c.key == this.timefilter);
                let dateBP = new Date(lastDate);
                dateBP = new Date(dateBP.setDate(dateBP.getDate() - tFilter.days));

                //console.log(dateBP, lastDate);
                return this.hvScoreDetail.chartData.filter(a => {
                    let dt = new Date(a.timeStampUtc);
                    let res = dateBP < dt && dt <= lastDate;
                    //console.log(dt, res);
                    return res;
                });
            },
            chartXAxisLabel() {
                return this.filteredData.map(a => this.$moment(a.timeStampUtc).format("DD-MM-YYYY"));
            },
            chartScoreData() {
                let data = null;
                if (this.filteredData != undefined && this.filteredData.length > 0) {
                    data = this.filteredData.map(a => {
                        if (new Date(a.timeStampUtc) <= new Date(Date.now()))
                            return a.score;
                        return 0;
                    });
                }

                return {
                    label: 'Score',
                    fill: false,
                    backgroundColor: '#66615b',
                    borderColor: '#66615b',
                    data: data,
                };
            },
            chartDataSets() {
                return [
                    this.chartScoreData,
                ];
            },
        },
        mounted: function () {
            this.initComponent();
        },
    }
</script>

<style scoped>
</style>