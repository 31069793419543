<template>
    <div>
        <h1>{{$t('accountViews.myAccount.header')}}</h1>

        <div class="row">
            <div class="col-12 col-md-6">
                <edit-account-form :accountDetail=accountDetail @accountDetailUpdated="accDetailUpdateHandler" data-v-step="step-edit"></edit-account-form>
            </div>
            <div class="col-12 col-md-6">
                <div class="card">
                    <div class="card-header">
                        <h4 class="title">{{$t('accountViews.myAccount.credentials.header')}}</h4>
                    </div>
                    <div class="card-body">
                        <div data-v-step="step-mfa">
                            <div class="row justify-content-between">
                                <div class="col-auto">
                                    <h6 class="mt-1">{{$t('accountViews.myAccount.credentials.mfa.header')}}</h6>
                                </div>
                                <div class="col-auto">
                                    <p-switch v-model="accountDetail.mfa" @input="enableMfa"></p-switch>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <span class="text-muted">
                                        {{$t('accountViews.myAccount.credentials.mfa.text')}} <a href="https://lazycompany.atlassian.net/wiki/spaces/LPS/pages/252411906/" target="_blank">{{$t('accountViews.myAccount.credentials.mfa.linkMore')}}</a>
                                    </span>
                                </div>
                            </div>
                            <div class="row" v-if="accountDetail.mfa">
                                <div class="col">
                                    <p-button type="warning" @click="showMfaDialog">{{$t('accountViews.myAccount.credentials.mfa.btnShowQr')}}</p-button>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4" data-v-step="step-pwd">
                            <change-password-form></change-password-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 col-md-6">
                <div class="card" data-v-step="step-erole">
                    <div class="card-header">
                        <h4 class="title">{{$t('accountViews.myAccount.entities.header')}}</h4>
                    </div>
                    <div class="card-body">
                        <ul class="list-unstyled team-members" v-if="accountDetail.entities.length > 0">
                            <li>
                                <div class="row mb-3" v-for="ent in accountDetail.entities">
                                    <div class="col-md-7 col-7">
                                        <h6 class="mb-0">
                                            {{ent.name}}
                                        </h6>
                                        <span class="text-muted">
                                            <small> {{$t('accountViews.myAccount.entities.beLabel')}}: {{ent.beName}}</small>
                                        </span>
                                    </div>
                                    <div class="col-md-5 col-5 text-right">
                                        <i :class="role.getRoleIcon(ent.role)" aria-hidden="true"></i>
                                        {{ent.role}}
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <p class="text-muted" v-else>
                            {{$t('accountViews.myAccount.entities.noEntities')}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="card" data-v-step="step-berole">
                    <div class="card-header">
                        <h4 class="title">{{$t('accountViews.myAccount.bes.header')}}</h4>
                    </div>
                    <div class="card-body">
                        <ul class="list-unstyled team-members" v-if="accountDetail.billingEntities.length > 0">
                            <li>
                                <div class="row mb-3" v-for="be in accountDetail.billingEntities">
                                    <div class="col-md-7 col-7">
                                        <h6 class="mb-0">
                                            {{be.name}}
                                        </h6>
                                        <span class="text-muted">
                                            <small> {{$t('accountViews.myAccount.bes.subLabel')}}: {{be.subscriptionType}}</small>
                                        </span>
                                        <br />
                                        <span class="text-muted">
                                            <small> {{$t('accountViews.myAccount.bes.expLabel')}}: {{be.subscriptionExpiration | moment("DD.MM.YYYY")}}</small>
                                        </span>
                                    </div>
                                    <div class="col-md-5 col-5 text-right">
                                        <i :class="role.getRoleIcon(be.role)" aria-hidden="true"></i>
                                        {{be.role}}
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <p class="text-muted" v-else>
                            {{$t('accountViews.myAccount.bes.noBEs')}}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <!-- be role modal -->
        <mfa-detail :show="modals.showMfaDialog"
                    :qrContent="accountDetail.mfaConfig"
                    @hide="mfaDetailHide">
        </mfa-detail>

    </div>
</template>
<script>
    import { Switch } from 'src/components/UIComponents'
    import EditAccountForm from './EditAccountForm.vue'
    import ChangePasswordForm from './ChangePasswordForm.vue'
    import { RoleEnum, SubscriptionTools } from 'src/lpLibrary.js'
    import swal from 'sweetalert2'
    import MfaDetail from 'src/components/UIComponents/ModalDialog/MfaDetail.vue'

    export default {
        components: {
            EditAccountForm,
            ChangePasswordForm,
            [Switch.name]: Switch,
            MfaDetail
        },
        mixins: [{ data() { return { role: RoleEnum } } }],
        data() {
            return {
                accountDetail: {
                    mfa: false,
                    mfaConfig: "",
                    entities:[],
                    billingEntities:[],
                },
                modals: {
                    showMfaDialog: false,
                },                
            }
        },
        created: function () {
            this.loadAccoountDetail();
        },
        methods: {
            loadAccoountDetail() {
                let vueThis = this;
                if (this.$store.getters.userState.accountHID == null || this.$store.getters.userState.accountHID === '') {
                    return;
                }
                let accountHID = this.$store.getters.userState.accountHID;
                this.axios.post(this.$root.config.account.getAccountDetailUrl(), null, { params: { accountHID: accountHID } })
                    .then(response => {
                        this.accountDetail = response.data;
                        this.accountDetail.billingEntities.forEach(be => {
                            be.subscriptionType = SubscriptionTools.getSubName(be.subscriptionTypeID);
                        });                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();                        
                    });
            },
            accDetailUpdateHandler(ad) {
                this.accountDetail = ad;
                this.$store.commit('updateUserName', this.accountDetail.firstName + ' ' + this.accountDetail.lastName);
            },
            enableMfa() {
                let vueThis = this;
                if (this.accountDetail.mfa) {
                    this.sendEnableMfaRequest(this.accountDetail.mfa);
                }
                else {
                    let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                    cfg.title = this.$t('accountViews.myAccount.credentials.mfa.disableAlert.msg');
                    cfg.text = this.$t('accountViews.myAccount.credentials.mfa.disableAlert.text');
                    swal.fire(cfg).then((result) => {
                        if (result.value) {
                            vueThis.sendEnableMfaRequest(vueThis.accountDetail.mfa);
                        }
                        else {
                            vueThis.accountDetail.mfa = true;
                        }
                    });
                }
            },
            sendEnableMfaRequest(mfaStatus) {
                let vueThis = this;
                this.axios.post(this.$root.config.account.manageMfaUrl(), null, { params: { enable: mfaStatus } })
                    .then(response => {
                        vueThis.loadAccoountDetail();

                        let msg = this.$t('accountViews.myAccount.credentials.mfa.enableMsg');
                        if (!vueThis.accountDetail.mfa)
                            msg = this.$t('accountViews.myAccount.credentials.mfa.disableMsg');

                        swal.fire({
                            icon: 'success',
                            title: msg,
                            showConfirmButton: true,
                            timer: vueThis.$root.config.alertTimer15,
                            timerProgressBar: true,
                        });
                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        vueThis.accountDetail.mfa = !vueThis.accountDetail.mfa;                        
                    });
            },
            showMfaDialog() {
                this.modals.showMfaDialog = true;
            },
            mfaDetailHide() {
                this.modals.showMfaDialog = false;
            },
        }
    }

</script>
<style>
    .card {
        height: calc(100% - 20px);
    }
</style>
