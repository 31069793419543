<template>
    <modal :show.sync="modalShow" headerClasses="justify-content-center" bodyClasses="user-detail" :clickOuterForClose="clickOuterForClose">
        <h4 slot="header" class="title title-up">{{title}}</h4>

        <form class="form-horizontal">

            <div class="row">
                <label class="col-md col-form-label text-left text-md-right">{{$t('modalDialogs.hvDetail.form.firstName.label')}}</label>
                <div class="col-md">
                    <fg-input :placeholder="$t('modalDialogs.hvDetail.form.firstName.placeholder')" v-model="hoaxVictim.firstName"></fg-input>
                </div>
            </div>

            <div class="row">
                <label class="col-md col-form-label text-left text-md-right">{{$t('modalDialogs.hvDetail.form.lastName.label')}}</label>
                <div class="col-md">
                    <fg-input :placeholder="$t('modalDialogs.hvDetail.form.lastName.placeholder')" v-model="hoaxVictim.lastName"></fg-input>
                </div>
            </div>

            <div class="row">
                <label class="col-md col-form-label text-left text-md-right">{{$t('modalDialogs.hvDetail.form.email.label')}}</label>
                <div class="col-md">
                    <fg-input :placeholder="$t('modalDialogs.hvDetail.form.email.placeholder')" v-model="hoaxVictim.email" :error="modelValidations.email.msg"></fg-input>
                </div>
            </div>

            <div class="row">
                <label class="col-md col-form-label text-left text-md-right">{{$t('modalDialogs.hvDetail.form.phoneNumber.label')}}</label>
                <div class="col-md mb-3">
                    <vue-phone-number-input v-model="phoneInput.phoneNumber" validColor="#dddddd" @update="phChanged" ref="vuepninput" :only-countries="supportedPhoneNumberCountries"
                                            :noExample="true" :noUseBrowserLocale="true" :error="phoneInput.showError" :translations="phoneNumberTranslations" />
                    <span class="error-text" style="color: #ef8157; font-size: 80%" v-if="phoneInput.showError">{{$t('modalDialogs.hvDetail.form.phoneNumber.err')}}</span>
                </div>
            </div>

            <div class="row">
                <label class="col-md col-form-label text-left text-md-right">{{$t('modalDialogs.hvDetail.form.group.label')}}</label>
                <div class="col-md">
                    <el-select multiple filterable
                                class="select-primary w-100"
                                v-model="selectedHvGroupHIDs"
                                :placeholder="$t('modalDialogs.hvDetail.form.group.placeholder')"
                                @remove-tag="removeHvG" @visible-change="visibleChangeHVG">
                        <el-option v-for="hvg in hvGroups"
                                    class="select-primary"
                                    :value="hvg.hid"
                                    :label="hvg.name"
                                    :key="hvg.hid"
                                    :disabled="hvg.isSystem">
                            <span style="float: left">{{ hvg.name }}</span>
                            <div style="float: right; margin-right: 15px;">
                                <el-tooltip :content="$t('modalDialogs.hvDetail.form.group.hvgTooltip')" :open-delay="300" placement="top" v-if="hvg.hasRunningCampaign">
                                    <i class="fa fa-rocket text-success" aria-hidden="true"></i>
                                </el-tooltip>
                            </div>
                        </el-option>
                    </el-select>
                    <el-tooltip :content="$t('modalDialogs.hvDetail.form.group.warnHvgMsg')" :open-delay="300" placement="top" v-if="showGroupWarning" class="ml-2 group-warning">
                        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                    </el-tooltip>
                </div>
            </div>
        </form>

        <div slot="footer" class="row mr-3">
            <div class="col">
                <p-button type="info" round @click="onClickSave">
                    {{confirmBtnLabel}}
                </p-button>
                <p-button type="default" round @click="onClickCancel">
                    {{$t('modalDialogs.hvDetail.btnCancel')}}
                </p-button>
            </div>
        </div>
    </modal>
</template>
<script>
    import VuePhoneNumberInput from 'vue-phone-number-input';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import { MaturityLevelTools, Tools } from 'src/lpLibrary.js'


    import { Modal } from 'src/components/UIComponents'
    import { Select, Option } from 'element-ui'
    import swal from 'sweetalert2'

    export default {
        name: 'hoax-victim-detail',
        components: {
            Modal,
            [Select.name]: Select,
            [Option.name]: Option,
            'vue-phone-number-input': VuePhoneNumberInput,
        },
        props: {
            title: String,
            confirmBtnLabel: String,
            entityHID: String,
            hoaxVictimHID: String,
            show: Boolean,
        },
        data() {
            return {
                modalShow: false,
                modelValidations: {
                    email: {
                        msg: '',
                    },
                },
                hvGroups: [],
                selectedHvGroupHIDs: [],
                showGroupWarning: false,
                warningHvGroupIds: [],
                hoaxVictim: {
                    groups: [],
                },
                phoneInput: {
                    phoneNumber: null,
                    //isValid: true,
                    showError: false,
                },
                clickOuterForClose: true,
            }
        },
        methods: {
            loadHvGroups() {
                if (!this.entityHID) return;
                let vueThis = this;
                this.axios.post(this.$root.config.entity.hvGroup.getHvGroupsUrl(this.entityHID), null)
                    .then(response => {
                        this.hvGroups = response.data;
                        this.hvGroups.forEach(function (item) {
                            if (item.isSystem) {
                                item.name = MaturityLevelTools.translateGroupName(item.name, item.isSystem);
                            }
                        });               
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                    });
            },
            loadHvDetail() {
                if (!this.hoaxVictimHID) return;
                let vueThis = this;
                this.axios.post(this.$root.config.entity.hv.hoaxVictimDetailUrl(this.entityHID), null, { params: { hvHID: this.hoaxVictimHID } })
                    .then(response => {
                        this.hoaxVictim = response.data;
                        this.phoneInput.phoneNumber = this.hoaxVictim.phoneNumber;                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                    });            },
            onClickCancel: function () {
                this.clear();
                this.$emit('hide', false);
            },
            onClickSave: function () {
                //if (!this.phoneInput.isValid) {
                //    this.phoneInput.showError = true;
                //    return;
                //}
                let phInput = this.$refs.vuepninput.results;
                if (!phInput.isValid && phInput.phoneNumber != undefined) {
                    this.phoneInput.showError = true;
                    return;
                }                
                if (this.validate()) {
                    let ng = [];
                    this.selectedHvGroupHIDs.forEach(item => {
                        ng.push(this.hvGroups.find(e => e.hid == item));
                    });
                    this.hoaxVictim.phoneNumber = phInput.e164;
                    this.hoaxVictim.groups = ng;
                    this.$emit('save', this.hoaxVictim);
                }

            },
            clearValidation: function () {
                this.modelValidations.email.msg = "";
                this.showGroupWarning = false;
            },
            validate: function () {
                if (!this.modalShow) return;
                var result = true;
                this.clearValidation();
                if (this.hoaxVictim.email == null || this.hoaxVictim.email.length == 0) {
                    this.modelValidations.email.msg = this.$t('modalDialogs.hvDetail.form.email.errReq');
                    result = false;
                }
                else {
                    if (!this.isValidEmail(this.hoaxVictim.email)) {
                        this.modelValidations.email.msg = this.$t('modalDialogs.hvDetail.form.email.errNotValid');
                        result = false;
                    }
                }

                return result;
            },
            phChanged(payload) {
                //console.log(payload);
                //if (payload.phoneNumber != null && payload.phoneNumber != undefined && payload.phoneNumber != "") {
                //    this.phoneInput.isValid = payload.isValid;
                //    if (payload.isValid) {
                //        this.phoneInput.showError = false;
                //        this.hoaxVictim.phoneNumber = payload.e164;
                //    }
                //}
                //else {
                //    this.phoneInput.isValid = true;
                //}

                if (payload.phoneNumber != null && payload.phoneNumber != undefined && payload.phoneNumber != "") {
                    if (payload.isValid) {
                        this.phoneInput.showError = false;
                    }
                    else {
                        this.phoneInput.showError = true;
                    }
                }
                else {
                    this.phoneInput.showError = false;
                }
            },
            clear() {
                this.hoaxVictim = { groups: [], };
                this.$refs.vuepninput.results = {};
                this.$refs.vuepninput.userLocale = null;
                this.phoneInput.phoneNumber = null;
                this.phoneInput.showError = false;
            },
            removeHvG(item) {
                let hvg = this.hvGroups.find(c => c.hid == item);
                if (hvg != undefined && hvg.isSystem) {
                    this.selectedHvGroupHIDs.push(item);
                }
            },
            visibleChangeHVG(visible) {
                //console.log(visible);
                if (visible) {
                    this.clickOuterForClose = false;
                }
                else {
                    setTimeout(() => { this.clickOuterForClose = true; }, 300);
                }
            }
        },
        watch: {
            show: function (value) {
                this.clear();
                this.modalShow = value;
            },
            modalShow: function (value) {
                if (value) {
                    this.loadHvDetail();
                    this.loadHvGroups();
                }
                else {
                    this.$emit('hide', false);
                }
            },
            hoaxVictim: {
                deep: true,
                handler() {
                    this.selectedHvGroupHIDs = this.hoaxVictim.groups.map(a => a.hid);
                    this.validate();
                }
            },
            selectedHvGroupHIDs: function (val) {
                let hvgHIDs = this.hoaxVictim.groups.map(c => c.hid);
                let diffHvgIds = Tools.Array_Diff(val, hvgHIDs);
                this.showGroupWarning = false;
                for (const gHID of diffHvgIds) {
                    let group = this.hvGroups.find(c => c.hid == gHID);
                    if (group.hasRunningCampaign) {
                        this.showGroupWarning = true;
                        break;
                    }
                }
            }
        },
        computed: {
            supportedPhoneNumberCountries() {
                return this.$root.config.supportedPhoneNumberCountries;
            },
            phoneNumberTranslations() {
                return {
                    countrySelectorLabel: this.$t("general.phone-number.countrySelectorLabel"),
                    countrySelectorError: this.$t("general.phone-number.countrySelectorError"),
                    phoneNumberLabel: this.$t("general.phone-number.phoneNumberLabel"),
                    example: this.$t("general.phone-number.example")
                };
            }
        },
        created: function() {
            this.clearValidation();
        }
    }
</script>
<style scoped>

</style>
