<template>
    <div class="phishing-reporting-container">
        <card>
            <div slot="header">
                <h4 class="card-title">{{$t('phishingReporting.byEmail.header')}}</h4>
            </div>
            <div>
                <p>
                    {{ $t('phishingReporting.byEmail.text1')}}
                </p>
                <p>
                    {{ $t('phishingReporting.byEmail.text2')}}
                </p>
                <p>
                    {{ $t('phishingReporting.byEmail.text3')}}
                </p>
                <p>
                    <b>{{ $t('phishingReporting.byEmail.emailTitle')}}</b>
                </p>
                <p>
                    {{$root.config.getPhishingReportingEmail}}
                </p>
            </div>
        </card>

        <div class="row" v-if="false">
            <div class="col-12 col-md-7">
                <card>
                    <div slot="header">
                        <h4 class="card-title">{{$t('phishingReporting.byOutlook.header')}}</h4>
                    </div>
                    <div>
                        <p>
                            {{ $t('phishingReporting.byOutlook.text1')}}
                        </p>
                        <p>
                            {{ $t('phishingReporting.byOutlook.text2')}}
                        </p>
                        <p>
                            {{ $t('phishingReporting.byOutlook.text3')}}
                        </p>
                        <p>
                            {{ $t('phishingReporting.byOutlook.text4')}}
                        </p>
                    </div>
                </card>
            </div>
            <div class="col-12 col-md-5">
                <card>
                    <div class="text-center mt-5">
                        <i class="fa-solid fa-fish-fins plugin-icon"></i>
                        <p>{{ $t('phishingReporting.byOutlook.iconLabel')}}</p>
                    </div>

                    <div class="text-center" slot="footer">
                        <a :href="$root.config.getMsOutlookManifestUrl" class="btn btn-default">
                            {{ $t('phishingReporting.byOutlook.btnDownloadLabel')}}
                        </a>
                    </div>

                </card>
            </div>

        </div>

    </div>
</template>

<script>
    import { Input } from 'element-ui'
    import { Modal, Card } from 'src/components/UIComponents'
    import swal from 'sweetalert2'

    export default {
        name: "phishing-reporting",
        components: {
            [Input.name]: Input,
            Card,
            Modal,
            Card,
        },
        props: {
            entityHID: {
                type: String,
                required: true
            }
        },
        data() {
            return {

            }
        },
        methods: {
            initComponent: function () {

            },
        },
        computed: {

        },
        mounted: function () {
            this.initComponent();
        },
        watch: {
            $route(to, from) {
                if (to !== from) {
                    this.entityHID = this.$route.params.ehid;
                    this.initComponent();
                }
            }
        },
    }
</script>

<style scoped>
    .plugin-icon{
        font-size: 900%;
    }
</style>