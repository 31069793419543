<template>
    <div ref="content">
        <card >
            <div class="card-header">
                <div class="row">
                    <div class="col  d-flex justify-content-start">
                        <h4 class="card-title" data-v-step="step-name">{{$t('cbgViews.cgbList.header')}}</h4>
                    </div>
                    <div class="col text-right">
                        <el-tooltip :content="$t('cbgViews.cgbList.btnRefreshTooltip')" :open-delay="300" placement="top">
                            <p-button type="neutral" class="mr-2" icon @click="refresh(true)">
                                <i class="fa-solid fa-arrows-rotate"></i>
                            </p-button>
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <!-- toolbar -->
                <div class="row toolbar">
                    <div class="col-12">
                        <div class="">
                            <router-link class="btn btn-success" :to="{ name: 'newCampaignBlockGroup'}" data-v-step="step-cbgnew" v-if="lazyAIStopped">
                                <i class="nc-icon nc-simple-add"></i> {{$t('cbgViews.cgbList.btnNewCBG')}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <!-- header -->
                <div class="row justify-content-between">
                    <div class="col-12 col-md-6 pt-0 pt-md-2">

                    </div>
                    <div class="col-12 col-md-6 col-xl-4 offset-xl-2 mt-2 mt-md-0">
                        <div class="d-flex justify-content-end">
                            <fg-input class="input-sm mr-2"
                                      :placeholder="$t('cbgViews.cgbList.searchPlaceholder')"
                                      v-model="searchQuery"
                                      addon-right-icon="nc-icon nc-zoom-split">
                            </fg-input>
                            <el-select class="select-default float-sm-left page-size"
                                       v-model="pagination.perPage">
                                <el-option class="select-default"
                                           v-for="item in pagination.perPageOptions"
                                           :key="item"
                                           :label="item"
                                           :value="item">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <!-- table -->
                <div class="row">
                    <div class="col-12" ref="table">
                        <el-table :data="queriedData" :empty-text="$t('cbgViews.cgbList.table.noData')"
                                  :default-sort="{ prop: 'name', order: 'ascending'}"
                                  @sort-change="handleSortChange">
                            <el-table-column prop="name" sortable="custom" :label="$t('cbgViews.cgbList.table.colName')" :min-width="33">
                                <template slot-scope="props">
                                    {{props.row.name}}
                                    <el-tooltip content="SMS" :open-delay="300" placement="top" v-if="props.row.channels.includes('sms')">
                                        <i class="fa fa-commenting-o ml-1" aria-hidden="true"></i>
                                    </el-tooltip>
                                    <el-tooltip content="Email" :open-delay="300" placement="top" v-if="props.row.channels.includes('email')">
                                        <i class="fa fa-envelope-o ml-1" aria-hidden="true"></i>
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                            <el-table-column prop="description" :label="$t('cbgViews.cgbList.table.colDesc')" :min-width="66">
                            </el-table-column>
                            <el-table-column prop="duration" :label="$t('cbgViews.cgbList.table.colDuration')" :width="150" sortable="custom" align="center">
                                <template slot-scope="props">
                                    {{props.row.sending + props.row.waiting}} days
                                </template>
                            </el-table-column>
                            <el-table-column prop="cultureCode" :label="$t('cbgViews.cgbList.table.colCC')" :width="150" sortable="custom" align="center">
                                <template slot-scope="props">
                                    {{props.row.cultureCode.toUpperCase()}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="difficulty" :label="$t('cbgViews.cgbList.table.colDiff')" :width="150" sortable="custom" align="center">
                            </el-table-column>
                            <el-table-column :width="120" align="center"
                                             class-name="td-actions">
                                <template slot="header" slot-scope="scope">
                                    <span data-v-step="step-actions">{{$t('cbgViews.cgbList.table.colActions.header')}}</span>
                                </template>
                                <template slot-scope="props">
                                    <el-tooltip :content="$t('cbgViews.cgbList.table.colActions.btnDelete')" :open-delay="300" placement="top" v-if="lazyAIStopped && props.row.canDelete">
                                        <p-button type="danger" size="sm" icon @click="handleDeleteCBG(props.row)">
                                            <i class="fa-solid fa-trash-can"></i>
                                        </p-button>
                                    </el-tooltip>
                                    <el-tooltip :content="$t('cbgViews.cgbList.table.colActions.btnDetail')" :open-delay="300" placement="top">
                                        <router-link class="btn btn-default btn-icon btn-sm" :to="{ name: 'eCampaignBlockGroup', params:{ cbhid: props.row.hid}}">
                                            <i class="fa-solid fa-circle-info"></i>
                                        </router-link>
                                    </el-tooltip>
                                </template>
                            </el-table-column>
                        </el-table>

                    </div>
                </div>
                <!-- paging -->
                <div class="row">
                    <div class="col-sm-6 pagination-info">
                        <p class="category">{{$t('cbgViews.cgbList.pagingInfo', [from + 1, to, total])}}</p>
                    </div>
                    <div class="col-sm-6">
                        <p-pagination class="pull-right"
                                      v-model="pagination.currentPage"
                                      :per-page="pagination.perPage"
                                      :total="pagination.total">
                        </p-pagination>
                    </div>
                </div>
            </div>

        </card>
    </div>
</template>

<script>
    import { GetCampaignStatusUIData, LazyAITools } from 'src/lpLibrary.js'

    import { Input, Select, Option, Table, TableColumn  } from 'element-ui'
    import { Collapse, CollapseItem, Tabs, TabPane, Card, Progress, Pagination } from 'src/components/UIComponents'
    import swal from 'sweetalert2'

    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }

    export default {
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            TabPane,
            Tabs,
            CollapseItem,
            Collapse,
            Card,
            [Progress.name]: Progress,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            [Pagination.name]: Pagination,
        },
        data() {
            return {
                templates: [],
                entityHID: '',
                lazyAIStopped: false,
                pagination: {
                    perPage: 25,
                    currentPage: 1,
                    perPageOptions: [10, 25, 50, 100],
                    total: 0
                },
                propsToSearch: ['name', 'countBlocks', 'cultureCode'],
                searchQuery: '',
                sortObject: { order: 'ascending', name: 'name' },
            }
        },
        methods: {
            initComponent: function () {
                this.entityHID = this.$route.params.ehid;
                this.lazyAIStopped = this.$store.getters.appState.entity.lazyAIStatus === LazyAITools.Status_Stopped;

                this.loadTemplates();
            },
            loadTemplates(force) {
                if (force == undefined) force = false;
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.content));
                this.axios.post(this.$root.config.entity.campBlockGroup.getCampaignBlockGroupsUrl(this.entityHID), null, { params: { force: force } })
                    .then(response => {
                        loader.hide();
                        this.templates = response.data;                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        loader.hide();
                    });
            },
            refresh(force) {
                this.loadTemplates(force);
            },
            //----------
            handleSortChange(e) {
                this.sortObject = { order: e.order, name: e.prop };
            },
            handleDeleteCBG(cbg) {
                let vueThis = this;
                let cfg = this.$root.config.getSweetAlertCfg_YesNo;
                cfg.title = vueThis.$t('cbgViews.cgbList.delAlert.msg', [cbg.name]);
                swal.fire(cfg).then((result) => {
                    if (result.value) {
                        vueThis.axios.post(vueThis.$root.config.entity.campBlockGroup.deleteCampaignBlockGroupUrl(vueThis.entityHID), null, { params: { cbgHID: cbg.hid } })
                            .then(response => {
                                vueThis.loadTemplates(true);
                                swal.fire({
                                    icon: 'success',
                                    title: vueThis.$t('cbgViews.cgbList.delAlert.success', [cbg.name]),
                                    showConfirmButton: true,
                                    timer: vueThis.$root.config.alertTimer15,
                                    timerProgressBar: true,
                                });
                            }).catch(function (error) {
                                if (error.response.status == 400) {
                                    switch (error.response.data.code) {
                                        default:
                                            swal.fire({
                                                icon: 'error',
                                                title: vueThis.$t('cbgViews.cgbList.delAlert.failed'),
                                                showConfirmButton: true,
                                                timer: vueThis.$root.config.alertTimer15,
                                                timerProgressBar: true,
                                            });
                                            break;
                                    }
                                }
                                else {
                                    vueThis.writeEx2Log(error);
                                    vueThis.genericErrorAlert();
                                }
                            });
                    }
                });
            },
        },
        mounted: function () {
            this.initComponent();
        },
        computed: {
            queriedData() {
                var cThis = this;
                if (!this.searchQuery) {
                    this.pagination.total = this.templates.length;
                    return this.templates.sort(function (a, b) { return sortingHandler(a, b, cThis.sortObject); }).slice(this.from, this.to);
                }
                let result = this.templates
                    .filter((row) => {
                        let isIncluded = false;
                        for (let key of this.propsToSearch) {
                            let rowValue = row[key].toString();
                            if (rowValue.includes && rowValue.toLowerCase().includes(this.searchQuery.toLowerCase())) {
                                isIncluded = true;
                            }
                        }
                        return isIncluded;
                    })
                this.pagination.total = result.length;
                return result.sort(function (a, b) { return sortingHandler(a, b, cThis.sortObject); }).slice(this.from, this.to);
            },
            to() {
                let highBound = this.from + this.pagination.perPage;
                if (this.total < highBound) {
                    highBound = this.total;
                }
                return highBound;
            },
            from() {
                if (this.pagination.total == 0) return -1;
                return this.pagination.perPage * (this.pagination.currentPage - 1);
            },
            total() {
                return this.pagination.total;
            },
        },
        watch: {
            $route(to, from) {
                if (to !== from) {
                    if (!this.$store.getters.appState.entity.manualControl) {
                        this.$router.push({ name: "aDashboard" });
                    }
                    else {
                        this.initComponent();
                    }
                }
            }
        }
    }


</script>

<style scoped>
</style>