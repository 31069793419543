<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col d-flex justify-content-start">
                    <h4 class="card-title" data-v-step="step-name">{{$t('trainingQuestionTable.header')}}</h4>
                </div>
                <div class="col text-right">
                    <el-tooltip :content="$t('trainingQuestionTable.btnRefreshTooltip')" :open-delay="300" placement="top">
                        <p-button type="neutral" class="mr-2" icon @click="refresh(true)">
                            <i class="fa-solid fa-arrows-rotate"></i>
                        </p-button>
                    </el-tooltip>
                </div>
            </div>
        </div>
        <div class="card-body" ref="table">
            <div class="row">
                <div class="col-12">
                    <!-- select -->
                    <div class="row justify-content-between">
                        <div class="col-12 col-md-6 pt-0 pt-md-2">
                            <dropdown-check :items="areas" v-model="filter.areas" :title="$t('trainingQuestionTable.filter.areas.placeholder')" class="mr-2"></dropdown-check>
                            <dropdown-check :items="focus" v-model="filter.focus" :title="$t('trainingQuestionTable.filter.focus.placeholder')" class="mr-2"></dropdown-check>

                            <el-tooltip :content="$t('trainingQuestionTable.filter.btnClearTooltip')" :open-delay="300" placement="top" v-if="filterDirty" class="text-muted py-0">
                                <el-button type="text" @click="clearFilter">
                                    <i class="fa-solid fa-xmark mr-1"></i>
                                    {{$t('trainingContentTable.filter.btnClear')}}
                                </el-button>
                            </el-tooltip>
                        </div>
                        <div class="col-12 col-md-6 col-xl-4 offset-xl-2 mt-2 mt-md-0">
                            <div class="d-flex justify-content-end">
                                <fg-input class="input-sm mr-2"
                                          :placeholder="$t('trainingQuestionTable.searchPlaceholder')"
                                          v-model="searchQuery"
                                          addon-right-icon="nc-icon nc-zoom-split">
                                </fg-input>
                                <el-select class="select-default float-sm-left page-size"
                                           v-model="pagination.perPage">
                                    <el-option class="select-default"
                                               v-for="item in pagination.perPageOptions"
                                               :key="item"
                                               :label="item"
                                               :value="item">
                                    </el-option>
                                </el-select>
                            </div>
                        </div>

                    </div>

                    <!-- table -->
                    <div class="row">
                        <div class="col-12" ref="table">
                            <el-table v-bind:data="queriedData" ref="eltable" :empty-text="$t('trainingQuestionTable.table.noData')"
                                      :default-sort="{ prop: 'title', order: 'ascending'}"
                                      @sort-change="handleSortChange">
                                <el-table-column prop="title" sortable="custom" :label="$t('trainingQuestionTable.table.colTitle')">
                                </el-table-column>
                                <el-table-column :width="120" align="center" class-name="td-actions">
                                    <template slot="header" slot-scope="scope">
                                        <span data-v-step="step-actions">{{$t('trainingQuestionTable.table.colActions.header')}}</span>
                                    </template>
                                    <template slot-scope="props">
                                        <el-tooltip :content="$t('trainingQuestionTable.table.colActions.btnDetailTooltip')" :open-delay="300" placement="top">
                                            <a :href="props.row.hostedDomainNameForSample" class="mr-2 ml-3 mt-2 btn btn-icon btn-default" target="_blank">
                                                <i class="fa-solid fa-circle-info"></i>
                                            </a>
                                        </el-tooltip>
                                    </template>
                                </el-table-column>
                            </el-table>

                        </div>
                    </div>



                    <!-- paging -->
                    <div class="row">
                        <div class="col-sm-6 pagination-info">
                            <p class="category">{{$t('trainingQuestionTable.pagingInfo', [from + 1, to, pagination.total])}}</p>
                        </div>
                        <div class="col-sm-6">
                            <p-pagination class="pull-right"
                                          v-model="pagination.currentPage"
                                          :per-page="pagination.perPage"
                                          :total="pagination.total">
                            </p-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Input, Select, Option, Table, Card, TableColumn, Button } from 'element-ui'
    import { Pagination } from 'src/components/UIComponents'
    import DropdownCheck from 'src/components/UIComponents/DropdownCheck.vue'

    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }

    export default {
        name: 'training-question-table',
        props: {
            entityHID: {
                type: String,
                required: true
            },
        },
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            [Button.name]: Button,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            Card,
            [Pagination.name]: Pagination,
            DropdownCheck,
        },
        data() {
            return {
                trainingQuestions: [],
                pagination: {
                    perPage: 25,
                    currentPage: 1,
                    perPageOptions: [25, 50, 100, 200, 500],
                    total: 0,
                },
                propsToSearch: ['title'],
                searchQuery: '',
                sortObject: { order: 'ascending', name: 'title' },
                sortTable: { prop: 'title', order: 'ascending' },

                filter: {},
                filterDirty: false,
                defaultFilter: {
                    areas: [],
                    focus: [],
                },
            }
        },
        methods: {
            initComponent: function (force) {
                this.loadQuestions(force);
            },
            loadQuestions(force) {
                if (!this.entityHID) return;
                if (force == undefined) force = false;
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.table));

                this.axios.post(this.$root.config.entity.training.getTrainingQuestionsUrl(this.entityHID), null, { params: { force: force } })
                    .then(response => {
                        loader.hide();
                        response.data.forEach(item => {
                            let tObjs = JSON.parse(item.jsonData);
                            let tObj = tObjs.find(c => c.lang === "en")
                            item.title = tObj.question;
                            item.jsonData = null;
                        });
                        this.trainingQuestions = response.data;
                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        loader.hide();
                    });
            },
            clearFilter() {
                this.filter = JSON.parse(JSON.stringify(this.defaultFilter));
                this.filterDirty = false;
            },
            refresh(force) {
                this.loadQuestions(force);
            },
            handleSortChange(e) {
                this.sortObject = { order: e.order, name: e.prop };

            },
        },
        computed: {
            areas() {
                return this.$root.config.areasDefinition.map(a => a.key);
            },
            focus() {
                return this.$root.config.paramsDefinition.map(a => a.key);
            },
            //----------------------------------
            queriedData() {
                var cThis = this;

                //----- filter
                let result = this.trainingQuestions;
                if (this.filter.areas.length > 0) {
                    result = result
                        .filter((row) => {
                            let values = cThis.$root.config.areasDefinition.filter(c => cThis.filter.areas.includes(c.key)).map(c => c.values).reduce(function (a, b) { return a.concat(b); }, []);
                            //console.log(values);
                            return values.some((item) => row.modules.includes(item));
                        });
                }
                if (this.filter.focus.length > 0) {
                    result = result
                        .filter((row) => {
                            let values = cThis.$root.config.paramsDefinition.filter(c => cThis.filter.focus.includes(c.key)).map(c => c.values).reduce(function (a, b) { return a.concat(b); }, []);

                            return values.some((item) => row.parameters.includes(item));
                        });
                }
                //----- search
                if (!this.searchQuery) {
                    this.pagination.total = result.length;
                    return result.sort(function (a, b) { return sortingHandler(a, b, cThis.sortObject); }).slice(this.from, this.to);
                }

                result = result
                    .filter((row) => {
                        let isIncluded = false;
                        for (let key of this.propsToSearch) {
                            let rowValue = row[key].toString();
                            if (rowValue.includes && rowValue.toLowerCase().includes(cThis.searchQuery.toLowerCase())) {
                                isIncluded = true;
                            }
                        }
                        return isIncluded;
                    });
                this.pagination.total = result.length;
                return result.sort(function (a, b) { return sortingHandler(a, b, cThis.sortObject); }).slice(this.from, this.to);
            },
            to() {
                let highBound = this.from + this.pagination.perPage;
                if (this.pagination.total < highBound) {
                    highBound = this.pagination.total;
                }
                return highBound;
            },
            from() {
                if (this.pagination.total == 0) return -1;
                return this.pagination.perPage * (this.pagination.currentPage - 1);
            },
        },
        watch: {
            filter: {
                deep: true,
                handler(n) {
                    if (JSON.stringify(this.defaultFilter) != JSON.stringify(this.filter)) {
                        this.filterDirty = true;
                    }
                    else {
                        this.filterDirty = false;
                    }
                }
            },
        },
        mounted: function () {
            this.initComponent();
        },
        created() {
            this.filter = JSON.parse(JSON.stringify(this.defaultFilter));
        },
    }
</script>

<style scoped>
</style>