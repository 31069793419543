<template>
    <div class="row">
        <div class="col-12" v-if="alert != null">
            <div class="alert fade show" :class="[`alert-${alert.type}`]">
                <span>
                    {{alert.message}}
                </span>
            </div>
        </div>

        <div class="col-12 col-md-6">
            <card>
                <div class="row" slot="header">
                    <div class="col d-flex justify-content-start">
                        <h4 class="card-title">{{ $t('accountViews.dashboard.lp.header')}}</h4>
                    </div>
                    <div class="col text-right icon">
                        <img src="/static/img/lp-logo.png" class="img-fluid" style="height:60px" />
                    </div>
                </div>
                <div>
                    <p>
                        {{ $t('accountViews.dashboard.lp.text1')}}
                    </p>
                    <p>
                        {{ $t('accountViews.dashboard.lp.text2')}}
                    </p>
                    <p>
                        {{ $t('accountViews.dashboard.lp.text3')}}
                    </p>
                    <p>
                        {{ $t('accountViews.dashboard.lp.text4')}}
                        <a href="mailto: info@lazyphish.com">info@lazyphish.com</a>.
                    </p>
                </div>
            </card>
        </div>
        <div class="col-12 col-md-6">
            <benchmark-card :reacted="globalBenchmark.reacted" :compromised="globalBenchmark.compromised"
                            :title="$t('accountViews.dashboard.failureRates.header')" :footerText="$t('accountViews.dashboard.failureRates.footer')"
                            ></benchmark-card>
        </div>

        <div class="col-12" ref="updatesContent">
            <card>
                <div class="row" slot="header">
                    <div class="col d-flex justify-content-start">
                        <h4 class="card-title">{{ $t('accountViews.dashboard.updates.header')}}</h4>
                    </div>
                    <div class="col text-right icon">
                        <i class="fa-solid fa-circle-info fa-3x"></i>
                    </div>
                </div>
                <div>
                    <el-empty :description="$t('accountViews.dashboard.updates.noUpdates')" v-if="updates.length == 0">
                    </el-empty>
                    <div v-else>
                        <div class="mt-1" v-for="a in updates">
                            <b>{{a.timeStamp}} - {{a.title}}</b>
                            <p>{{a.message}}</p>
                        </div>
                    </div>

                </div>
            </card>
        </div>

    </div>
</template>
<script>
    import { Empty } from 'element-ui'
    import BenchmarkCard from 'src/components/UIComponents/Cards/BenchmarkCard.vue'


    export default {
        components: {
            [Empty.name]: Empty,
            [BenchmarkCard.name]: BenchmarkCard,
        },
        data() {
            return {
                dashboardInfo: null,
                globalBenchmarkData: null,
            }
        },
        methods: {
            initComponent: function () {
                this.loadDashboardInfo();
                this.loadGlobalBenchmark();                
            },
            loadDashboardInfo() {
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.updatesContent));
                this.axios.get(this.$root.config.dashboardInfoUrl(this.$root.cultureCode), { withCredentials: false }).then(response => {
                    this.dashboardInfo = response.data;
                    loader.hide();
                }).catch(function (ex) {
                    vueThis.writeEx2Log(ex);
                    loader.hide();
                });
            },
            loadGlobalBenchmark() {
                let vueThis = this;
                this.axios.get(this.$root.config.globalBenchmark, { withCredentials: false }).then(response => {
                    this.globalBenchmarkData = response.data;
                }).catch(function (ex) {
                    vueThis.writeEx2Log(ex);
                });
            },
        },
        computed: {
            updates() {
                if (this.dashboardInfo == null) return [];
                return this.dashboardInfo.updates;
            },
            alert() {
                if (this.dashboardInfo == null) return null;
                if (this.dashboardInfo.alert.type == "") return null;
                return this.dashboardInfo.alert;
            },
            globalBenchmark() {
                if (this.globalBenchmarkData == null) return { reacted: 0, compromised: 0 };
                return this.globalBenchmarkData;
            },
        },
        mounted: function () {
            this.initComponent();
        },
        watch: {
            "$root.cultureCode"(){
                this.loadDashboardInfo();
            }
        }
    }

</script>
<style  lang="scss">
    .icon {
        color: #6d6d6c;
    }
    .doc-section {
        text-transform: capitalize;

        .fa {
            color: #6d6d6c;
        }

        a h5 span {
            font-size: smaller;
        }
    }


</style>
