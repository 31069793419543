<template>
    <div class="card">
        <div class="card-header">
            <h4 class="title">{{ $t('accountViews.editAccountForm.header')}}</h4>
        </div>
        <div class="card-body">
            <form>
                <div class="row">
                    <div class="col-md-6">
                        <fg-input type="text"
                                  :label="$t('accountViews.editAccountForm.form.userName')"
                                  :placeholder="$t('accountViews.editAccountForm.form.userName')"
                                  v-model="accountDetail.email"
                                  :disabled="true">
                        </fg-input>
                    </div>
                    <div class="col-md-6">
                        <fg-input type="email"
                                  :label="$t('accountViews.editAccountForm.form.email')"
                                  :placeholder="$t('accountViews.editAccountForm.form.email')"
                                  v-model="accountDetail.email"
                                  :disabled="true">
                        </fg-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <fg-input type="text"
                                  :label="$t('accountViews.editAccountForm.form.firstName.label')" :error="getError('firstName')" 
                                  :placeholder="$t('accountViews.editAccountForm.form.firstName.placeholder')" v-validate="modelValidations.firstName" name="firstName"
                                  v-model="accountDetail.firstName">
                        </fg-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <fg-input type="text"
                                  :label="$t('accountViews.editAccountForm.form.lastName.label')" :error="getError('lastName')"
                                  :placeholder="$t('accountViews.editAccountForm.form.lastName.placeholder')" v-validate="modelValidations.lastName" name="lastName"
                                  v-model="accountDetail.lastName">
                        </fg-input>
                    </div>
                </div>
                <div class="row mt-3 justify-content-center">
                    <div class="col-12 col-md-auto text-center">
                        <p-button native-type="submit" type="info" class="w-100" @click.prevent="validate">
                            {{ $t('accountViews.editAccountForm.btnUpdate')}}
                        </p-button>
                    </div>
                </div>
                <div class="clearfix"></div>
            </form>
        </div>
    </div>
</template>
<script>
    import swal from 'sweetalert2'

    export default {
        props: ['accountDetail'],
        data() {
            return {
                modelValidations: {
                    firstName: {
                        required: true,
                        max: 50,
                    },
                    lastName: {
                        required: true,
                        max: 50,
                    },
                },
            }
        },
        methods: {
            updateAccount() {
                let vueThis = this;
                let submitData = this.accountDetail;
                submitData.entities = [];
                submitData.billingEntities = [];
                this.axios.post(this.$root.config.account.getUpdateAccountUrl(), submitData)
                    .then(response => {
                        console.log(response.data);
                        this.$emit('accountDetailUpdated', response.data);
                        swal.fire({
                            icon: 'success',
                            title: vueThis.$t('accountViews.editAccountForm.updateSuccess'),
                            showConfirmButton: true,
                            timer: vueThis.$root.config.alertTimer15,
                            timerProgressBar: true,
                        });
                    }).catch(function (error) {
                        if (error.response.status == 400) {
                            swal.fire({
                                icon: 'error',
                                title: vueThis.$t('accountViews.editAccountForm.updateFailed'),
                                showConfirmButton: true,
                                timer: vueThis.$root.config.alertTimer15,
                                timerProgressBar: true,
                            });
                        }
                        else {
                            vueThis.writeEx2Log(error);
                            vueThis.genericErrorAlert();
                        }
                    });
            },
            getError(fieldName) {
                let familierName = this.$t('accountViews.editAccountForm.form.' + fieldName + '.familierName');
                var errMsg = this.errors.first(fieldName);
                if (errMsg === undefined) return errMsg;
                errMsg = errMsg.replace(fieldName, familierName);
                return errMsg;
                //return this.errors.first(fieldName)
            },
            validate() {
                this.$validator.validateAll().then(isValid => {
                    if (isValid) {
                        this.updateAccount();
                    }
                })
            }
        }
    }

</script>
<style>

</style>
