<template>
    <div>
        <div class="row">
            <div class="col-12 hvresult-container" ref="hvresult">
                <el-table :data="hvResults" :empty-text="$t('hvResult.hvResultTable.table.noData')"
                          :default-sort="{ prop: 'campaignHID', order: 'ascending'}"
                          @sort-change="handleSortChange">
                    <el-table-column type="expand">
                        <template slot-scope="props">
                            <hv-result-timeline :hvHID="hvHID" :entityHID="entityHID" :resultGroupHID="props.row.resultGroupHID"></hv-result-timeline>
                        </template>
                    </el-table-column>
                    <el-table-column prop="campaignName" :label="$t('hvResult.hvResultTable.table.colCamp')" v-if="showCampaign"></el-table-column>
                    <el-table-column prop="campaignBlockName" :label="$t('hvResult.hvResultTable.table.colCb')"></el-table-column>
                    <el-table-column prop="hvGroupName" :label="$t('hvResult.hvResultTable.table.colHvg')" v-if="showGroup">
                        <template slot-scope="{row}">
                            {{translateGroupName(row.hvGroupName, row.isHvGroupSystem)}}
                        </template>
                    </el-table-column>
                    <el-table-column :width="colwidth" align="center">
                        <div slot="header" class="text-initiated">
                            <i class="fa-solid fa-paper-plane" aria-hidden="true"></i>
                        </div>
                        <div slot-scope="{row}" class="td-number">
                            <i class="fa fa-check" aria-hidden="true" v-if="row.initiated"></i>
                            <el-tooltip :content="row.nextSend" :open-delay="300" placement="top" v-else="row.initiated">
                                <i class="fa-regular fa-clock"></i>
                            </el-tooltip>
                        </div>
                    </el-table-column>
                    <el-table-column :width="colwidth" align="center">
                        <div slot="header" class="text-interacted">
                            <i class="fa-solid fa-envelope-open-text" aria-hidden="true"></i>
                        </div>
                        <div slot-scope="{row}" class="td-number">
                            <div v-if="row.interacted" class="danger-result">
                                <i class="fa fa-exclamation" aria-hidden="true"></i>
                                <span v-if="row.countReInteracted > 0">
                                    ({{row.countReInteracted}})
                                </span>
                            </div>
                        </div>
                    </el-table-column>
                    <el-table-column :width="colwidth" align="center">
                        <div slot="header" class="text-reacted">
                            <i class="fa-solid fa-hand-point-up" aria-hidden="true"></i>
                        </div>
                        <div slot-scope="{row}" class="td-number">
                            <div v-if="row.reacted" class="danger-result">
                                <i class="fa fa-exclamation" aria-hidden="true"></i>
                                <span v-if="row.countReReacted > 0">
                                    ({{row.countReReacted}})
                                </span>
                            </div>
                        </div>
                    </el-table-column>
                    <el-table-column :width="colwidth" align="center">
                        <div slot="header" class="text-compromised">
                            <i class="fa fa-bomb" aria-hidden="true"></i>
                        </div>
                        <div slot-scope="{row}" class="td-number">
                            <div v-if="row.compromised" class="danger-result">
                                <i class="fa fa-exclamation" aria-hidden="true"></i>
                                <span v-if="row.countReCompromised > 0">
                                    ({{row.countReCompromised}})
                                </span>
                            </div>
                        </div>
                    </el-table-column>
                    <el-table-column :width="colwidth" align="center">
                        <div slot="header" class="text-reported">
                            <i class="fa fa-flag" aria-hidden="true"></i>
                        </div>
                        <div slot-scope="{row}" class="td-number">
                            <div v-if="row.reported" class="success-result">
                                <i class="fa fa-check" aria-hidden="true"></i>
                            </div>
                        </div>
                    </el-table-column>
                    <el-table-column :width="colwidth" align="center">
                        <div slot="header" class="text-trained">
                            <i class="fa fa-graduation-cap" aria-hidden="true"></i>
                        </div>
                        <div slot-scope="{row}" class="td-number">
                            <div v-if="row.trained" class="success-result">
                                <i class="fa fa-check" aria-hidden="true"></i>
                            </div>
                        </div>
                    </el-table-column>
                    <el-table-column :width="colwidth" align="center">
                        <div slot="header" class="text-block-score">
                            <i class="fa fa-shield" aria-hidden="true"></i>
                        </div>
                        <div slot-scope="{row}" class="td-number" v-if="row.blockScore != null">
                            <div class="score-icon" v-bind:style="[row.scoreColor]" v-if="viewBy != 'campaign'"></div>
                            {{row.blockScore}}
                        </div>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
    import { MaturityLevelTools } from 'src/lpLibrary.js'

    import { Input, Select, Option, Table, Card, TableColumn } from 'element-ui'
    import { Modal, Pagination } from 'src/components/UIComponents'
    import hvrTimeline from 'src/components/UIComponents/HVResult/HVResultTimeLine.vue'


    import swal from 'sweetalert2'

    function sortingHandler(a, b, sortObject) {
        let comparison = 0;
        let sign = sortObject.order == "descending" ? -1 : 1;
        if (a[sortObject.name] > b[sortObject.name]) {
            comparison = 1 * sign;
        } else if (a[sortObject.name] < b[sortObject.name]) {
            comparison = -1 * sign;
        }
        return comparison;
    }

    export default {
        name: 'hv-result-table',
        props: {
            entityHID: {
                type: String,
                required: true
            },
            hvHID: String,
            campaignHID: String,
            hvGroupHID: String,
            viewBy: {
                type: String,
                default: 'entity',
                description: '[entity|campaign|group]'
            }
        },
        components: {
            [Input.name]: Input,
            [Option.name]: Option,
            [Select.name]: Select,
            [Table.name]: Table,
            [TableColumn.name]: TableColumn,
            Modal,
            Card,
            [Pagination.name]: Pagination,
            [hvrTimeline.name]: hvrTimeline,
        },
        data() {
            return {
                hvResults: [],
                colwidth:80,
            }
        },
        methods: {
            initComponent: function () {
                this.loadHvResults();
            },
            loadHvResults: function () {
                if (this.hvHID === undefined || this.hvHID === '') {
                    return;
                }
                let vueThis = this;
                let loader = this.$loading.show(this.$root.config.getLoadingCfg(this.$refs.hvresult));
                this.axios.post(this.$root.config.entity.report.hoaxVictimResultsUrl(this.entityHID), null, { params: { hvHID: this.hvHID, campaignHID: this.campaignHID, hvGroupHID: this.hvGroupHID } })
                    .then(response => {
                        loader.hide();
                        this.hvResults = response.data;
                        this.hvResults.forEach(function (item) {
                            item.nextSend = item.nextSendUtc == null ? "---" : vueThis.$moment(item.nextSendUtc).format("DD.MM.YYYY HH:mm");
                            item.scoreColor = { backgroundColor: `hsl(${item.blockScore}, 95%, 50%)` };
                        });                        
                    }).catch(function (error) {
                        vueThis.writeEx2Log(error);
                        vueThis.genericErrorAlert();
                        loader.hide();
                    });
            },
            handleSortChange(e) {
                this.sortObject = { order: e.order, name: e.prop };
            },
            translateGroupName(name, isSystem) {
                return MaturityLevelTools.translateGroupName(name, isSystem);
            },
        },
        computed: {
            showCampaign() {
                let res = true;
                if (this.viewBy === 'campaign') res = false;
                return res;
            },
            showGroup() {
                let res = true;
                if (this.viewBy === 'group') res = false;
                return res;
            },
        },
        mounted: function () {
            this.initComponent();
        },
    }

</script>

<style scoped>
    .danger-result {
        color: #ef8157;
    }
</style>