<template>
    <modal :show.sync="modalShow" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">{{ $t('modalDialogs.mfaDetail.header')}}</h4>
        <div class="row">
            <div class="col d-flex justify-content-center">
                <qr-code :text="qrContent"></qr-code>
            </div>
        </div>
        <div slot="footer" class="row mr-3">
        <div class="col">
            <p-button type="info" round @click="onClickClose">
                {{ $t('modalDialogs.mfaDetail.btnClose')}}
            </p-button>
        </div>
        </div>
    </modal>
</template>

<script>
    import { Modal } from 'src/components/UIComponents'
    import VueQRCodeComponent from 'vue-qrcode-component'

    export default {
        name: 'mfa-detail',
        components: {
            Modal,
            'qr-code': VueQRCodeComponent,
        },
        props: {
            qrContent: String,
            show: Boolean,
        },
        data() {
            return {
                modalShow: false,
            }
        },
        methods: {
            onClickClose: function () {
                this.$emit('hide', false);
            },
        },
        watch: {
            show: function (value) {
                this.modalShow = value;
            },
            modalShow: function (value) {
                if (value) {
                }
                else {
                    this.$emit('hide', false);
                }
            },
        }
    }
</script>

<style scoped>
</style>