<template>
    <modal :show.sync="modalShow" headerClasses="justify-content-center">
        <h4 slot="header" class="title title-up">{{ $t('modalDialogs.beRoleDetail.header')}}</h4>

            <form class="form-horizontal">
                <div class="row">
                    <label class="col-md col-form-label text-left text-md-right">{{ $t('modalDialogs.beRoleDetail.form.beName.label')}}</label>
                    <div class="col-md">
                        <fg-input v-model="billingEntityName" readonly></fg-input>
                    </div>
                </div>

                <div class="row">
                    <label class="col-md col-form-label text-left text-md-right">{{ $t('modalDialogs.beRoleDetail.form.role.label')}}</label>
                    <div class="col-md">
                        <el-select class="select-primary"
                                    v-model="detailData.roleId"
                                    placeholder="Select role">
                            <el-option v-for="role in roles"
                                        class="select-primary"
                                        :value="role.id"
                                        :label="role.name"
                                        :key="role.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>

                <div class="row">
                    <label class="col-md col-form-label mt-2 text-left text-md-right">{{ $t('modalDialogs.beRoleDetail.form.accountEmail.label')}}</label>
                    <div class="col-md mt-2">
                        <fg-input :error="getError('accountEmail')">
                            <el-select class="select-primary" filterable remote :loading="findingAccount" default-first-option
                                        name="accountEmail" allow-create :remote-method="findAccount"
                                        v-validate="modelValidations.accountEmail"
                                        v-model="detailData.email"
                                        placeholder="Email address">
                                <el-option v-for="item in accountResult"
                                            :key="item.email"
                                            :label="item.email"
                                            :value="item.email">
                                </el-option>
                            </el-select>
                        </fg-input>
                    </div>
                </div>

            </form>

            <div slot="footer" class="row mr-3">
                <div class="col">
                    <p-button type="info" round @click="onClickSave">
                        {{ $t('modalDialogs.beRoleDetail.btnSave')}}

                    </p-button>
                    <p-button type="info" round @click="onClickCancel">
                        {{ $t('modalDialogs.beRoleDetail.btnCancel')}}
                    </p-button>
                </div>
            </div>
    </modal>
</template>
<script>
    import { Modal } from 'src/components/UIComponents'
    import { Select, Option } from 'element-ui'
    import swal from 'sweetalert2'
    import { RoleEnum } from 'src/lpLibrary.js'

    export default {
        name: 'be-role-detail',
        components: {
            Modal,
            [Select.name]: Select,
            [Option.name]: Option
        },
        props: {
            billingEntityHID: String,
            billingEntityName: String,
            detailData: Object,
            show: Boolean,
        },
        data() {
            return {
                modalShow: false,
                modelValidations: {
                    accountEmail: {
                        required: true,
                        email: true,
                    },
                },
                findingAccount: false,
                accountResult:[],
            }
        },
        methods: {
            onClickCancel: function () {
                this.$emit('hide', false);
            },
            onClickSave: function () {
                let vueThis = this;
                this.$validator.validateAll().then(isValid => {
                    if (isValid) {
                        this.$emit('save');
                    }
                })
            },
            findAccount: function (query) {
                let vueThis = this;
                if (query !== '' && query.length > 3) {
                    this.findingAccount = true;
                    this.axios.post(this.$root.config.account.getAccountsUrl(), null, { params: { query: query}})
                        .then(response => {
                            this.findingAccount = false;
                            this.accountResult = response.data;
                        }).catch(function (error) {
                            vueThis.writeEx2Log(error);
                            this.findingAccount = false;
                        });
                } else {
                    this.accountResult = [];
                }
            },
            getError(fieldName) {
                let familierName = this.$t('modalDialogs.beRoleDetail.form.' + fieldName + '.familierName');
                var errMsg = this.errors.first(fieldName);
                if (errMsg === undefined) return errMsg;
                errMsg = errMsg.replace(fieldName, familierName);
                return errMsg;
            },
        },
        computed: {
            roles() {
                return [
                    { name: RoleEnum.AdminName, id: RoleEnum.AdminID },
                ];
            },
        },
        watch: {
            show: function (value) {
                this.modalShow = value;
            },
            modalShow: function (value) {
                if (value) {
                    this.errors.clear();
                    this.detailData.roleId = RoleEnum.AdminID;
                }
                else {
                    this.$emit('hide', false);
                }
            },
        },
    }
</script>
<style scoped>
</style>
